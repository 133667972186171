import React, { VFC } from "react";
import { Link } from "gatsby";
import classNames from "classnames";

export interface NavLogoProps {
    items?: Array<{
        id: string;
        name: string;
        active?: boolean;
    }>
    className?: string;
}

const NavLogo: VFC<NavLogoProps> = ({ className = "" }) => {
    // const [showSmallLogo, setSmallLogo] = useState(false);

    // useEffect(() => {
    //     if(scrollY >= 5 !== showSmallLogo){
    //         setSmallLogo(scrollY >= 5);
    //     }
    // }, [showSmallLogo, scrollY]);

    const logoClassNames = classNames("cursor-pointer transition-all duration-700",
        "w-16 h-16 mr-2 mt-2 opacity-40 hover:opacity-90 relative", {
        // "md:w-36 md:h-36": !showSmallLogo,
            "md:w-28 md:h-28": true,
        });

    return (
        <div className={"flex-grow transition-all duration-1000 " + className}>
            <Link to="/">
                <img src="/logo.svg" alt="Raha Faridi" className={logoClassNames} />
            </Link>
        </div>
    );
};

export default NavLogo;
