import React, { VFC } from "react";
import type { IconProps } from "./@types";

const VimeoIcon: VFC<IconProps> = ({ className = "" }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`h-5 w-5 md:h-6 md:w-6 transition-all ${className}`}
            viewBox="0 0 24 24"
            role="img"
            // stroke="currentColor"
            fill="currentColor"
        >
            <title>Vimeo</title>
            <path d="M 21.988281 7.96875 C 21.902344 9.878906 20.539063 12.488281 17.910156 15.804688 C 15.191406 19.269531 12.890625 21 11.007813 21 C 9.84375 21 8.855469 19.945313 8.050781 17.835938 C 7.511719 15.902344 6.976563 13.96875 6.4375 12.035156 C 5.839844 9.925781 5.195313 8.871094 4.511719 8.871094 C 4.359375 8.871094 3.835938 9.179688 2.941406 9.792969 L 2 8.605469 C 2.988281 7.757813 3.960938 6.90625 4.917969 6.058594 C 6.234375 4.941406 7.222656 4.355469 7.882813 4.296875 C 9.4375 4.148438 10.398438 5.191406 10.757813 7.425781 C 11.144531 9.832031 11.414063 11.332031 11.5625 11.917969 C 12.011719 13.914063 12.507813 14.910156 13.046875 14.910156 C 13.464844 14.910156 14.09375 14.265625 14.933594 12.96875 C 15.769531 11.671875 16.21875 10.6875 16.277344 10.007813 C 16.398438 8.890625 15.949219 8.328125 14.933594 8.328125 C 14.453125 8.328125 13.960938 8.4375 13.453125 8.652344 C 14.433594 5.496094 16.3125 3.964844 19.085938 4.050781 C 21.140625 4.109375 22.109375 5.414063 21.988281 7.96875 Z"/>
        </svg>
    );
};

export default VimeoIcon;