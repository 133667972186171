import classNames from "classnames";
import React, { VFC } from "react";

interface PodcastCardProps {
    className?: string;
}

const PodcastCard:VFC<PodcastCardProps> = ({className}) => {
    const classes = classNames({
        " ": true
    }); 

    return (
        <div className={`${classes} ${className}`}>
            ttt
            {/* TODO */}
        </div>
    );
};

export default PodcastCard;
