/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from 'react';
import { Footer } from '../components';
import TopMenu from './TopMenu';
// @ts-expect-error import error
import Logo from './homelogo.png';
import Seo from '../parts/Seo';
import BackgroundComponent from './BackgroundComponent'; 

function getRandomInt(min: number, max: number): number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const Layout: React.FC<any> = ({ path, children }) => {
  const [showChild, setShowChild] = useState(false);
  const [isHomePage, setIsHomePage] = useState(path === '/');
  const [showImageBG, setShowImageBG] = useState(true);

  useEffect(() => {
    const explicitPathsWithImageBg = [
      '/',
      '/about',
      '/film',
      '/music',
      '/radio/',
      '/about/',
      '/film/',
      '/music/',
      '/radio/',
    ];
    setShowImageBG(explicitPathsWithImageBg.some((p) => p === path));
    setIsHomePage(path === '/');
    setShowChild(true);
  }, []);

  useEffect(() => {
    setIsHomePage(path === '/');
    const explicitPathsWithImageBg = [
      '/',
      '/about',
      '/film',
      '/music',
      '/radio/',
      '/about/',
      '/film/',
      '/music/',
      '/radio/',
    ];
    setShowImageBG(explicitPathsWithImageBg.some((p) => p === path));
  }, [path]);

  if (!showChild) {
    return null;
  }

  return (
    <>
      {/* Conditionally render BackgroundComponent based on showImageBG */}
      {showImageBG && <BackgroundComponent showImageBG={showImageBG} />}

      <div
        id="after_bg"
        className="m-auto w-full h-full flex flex-row-reverse items-start min-h-screen lg:p-0"
      >
        {isHomePage && (
          <div className="md:m-auto w-full h-full">
            <div
              className="flex flex-col-reverse h-screen justify-center lg:items-center lg:flex-row mt-4 md:mt-0 ml-2 mr-2"
              style={{ maxHeight: '90vh', width: '90wh' }}
            >
              <Seo title="Home" />
              <TopMenu isHomePage={true} />
              <div
                className={
                  'lg:w-auto animate-fadedIn flex flex-col justify-center lg:h-full'
                }
              >
                <img
                  src={Logo}
                  alt="Raha Faridi"
                  className="w-full opacity-70 pointer-events-none max-h-full"
                  loading="eager"
                />
              </div>
            </div>
          </div>
        )}

        {!isHomePage && (
          <div className="flex flex-col w-full min-h-screen">
            <TopMenu isHomePage={false} />
            <main className="flex-auto flex-grow mb-2">{children}</main>
            <Footer />
          </div>
        )}
      </div>
    </>
  );
};

export default Layout;
