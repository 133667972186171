/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC } from 'react';
import { Link } from 'gatsby';
import ProgressiveImage from 'react-progressive-image';
import classNames from 'classnames';
import ListCardText from '../ListCardText';

interface MovieCardProps {
  name: string;
  id: string;
  year: number;
  img: string;
  thumbnail: string;
  duration?: string | number;
  className?: string;
  link: string;
}

const MovieCard: FC<MovieCardProps> = ({
  id,
  name,
  year,
  link,
  img,
  thumbnail,
  className,
}) => {
  const classes = classNames({
    'movie-card list-card': true,
    ' w-full xs:w-96 sm:w-72 md:w-80 lg:w-68 xl:w-96 2xl:w-112 3xl:w-112 4xl:w-128':
      true, // cursor-pointer
  });

  return (
    // @ts-expect-error aaaaaa
    <ProgressiveImage
      src={img}
      //  placeholder={thumbnail}
    >
      {(src: string, loading: boolean) => {
        return (
          <div className={`${classes} ${className} ${link? 'cursor-pointer' : 'cursor-not-allowed'}`}>
            <Link to={link}>
              <div className={`px-2 ${link? 'cursor-pointer' : 'cursor-not-allowed'}`}>
                <img
                  src={src}
                  alt={name}
                  loading="lazy"
                  className={
                    'filter transition-all duration-500 object-center transform-gpu rounded shadow-lg w-192 md:w-full ' +
                    (loading ? 'opacity-0' : 'opacity-100')
                  }
                />
              </div>
              <ListCardText
                className={loading ? 'opacity-0' : 'opacity-100 image-ready'}
              >
                {year}
              </ListCardText>
            </Link>
          </div>
        );
      }}
    </ProgressiveImage>
  );
};

export default MovieCard;
