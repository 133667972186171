import React, { FC } from "react";
import { Link } from "gatsby";
import classNames from "classnames";
import NavLogo from "../components/src/Navigation/NavLogo";

const TopMenu: FC<{ isHomePage: boolean }> = ({ isHomePage }) => {
    // const rootPath = `${__PATH_PREFIX__}/`;

    function isCurrent(word: string): boolean {
        if (typeof window !== "undefined") {
            return window?.location.href.includes(word) ? true : false;
        }
        return false;
    }

    const menuItems = React.useMemo(() => {
        return isHomePage?  [
            {
                id: "about",
                name: "Raha",
                active: isCurrent("about")
            },
            {
                id: "film",
                name: "Film",
                active: isCurrent("film")
            },
            {
                id: "music",
                name: "Music",
                active: isCurrent("music")
            },
            {
                id: "radio",
                name: "Radio",
                active: isCurrent("radio")
            }
        ] : [
            {
                id: "about",
                name: "Raha",
                active: isCurrent("about")
            },
            {
                id: "film",
                name: "Film",
                active: isCurrent("film")
            },
            {
                id: "music",
                name: "Music",
                active: isCurrent("music")
            },
            {
                id: "radio",
                name: "Radio",
                active: isCurrent("radio")
            }
        ];
    }, [isHomePage, isCurrent]);

    const classes = classNames({
        "top-menu-wrapper": true,
        "pl-4 pr-2 xs:pl-8 sm:max-w-sm md:max-w-3xl": !isHomePage,
        "flex flex-row items-center justify-between": !isHomePage,
        "h-20 md:h-24": !isHomePage,
        "lg:h-full flex flex-col lg:justify-center items-baseline ml-4 -mt-10 sm:-mt-36 md:-mt-40 lg:mt-0": isHomePage
    });

    const menuItemClasses = classNames({
        "top-menu-item": true,
        "hover:opacity-100 flex items-center text-white transition menu duration-300": true,
        "text-base text-sm 2xs:text-lg sm:text-xl md:text-3xl lg:text-4xl mr-2 xs:mr-4": !isHomePage,
        "text-4xl xs:text-5xl sm:text-6xl md:text-7xl lg:text-8xl mt-2 lg:mt-8 opacity-90 justify-start lg:px-4": isHomePage
    });

    return (
        <div className={`flex justify-between ${isHomePage ? "" :  "w-full"}`}>
            <div className={classes + " "}>
                {menuItems.map(({ id, name, active }) => {
                    return <span className={
                        `${active ? "cursor-default" : "font-light cursor-pointer opacity-40"} ${menuItemClasses}`
                    } key={id}>
                        <Link to={`/${id}`}>
                            {name}
                        </Link>
                    </span>;
                })}
            </div>
            <div className="flex justify-end items-center">
                <NavLogo className={isHomePage ? "opacity-0" : "opacity-100"} />
            </div>
        </div>
    );
};

export default TopMenu;
