import React, { useMemo } from 'react';
import classNames from 'classnames';

interface ButtonProps extends Omit<React.HTMLProps<HTMLButtonElement>, 'size'> {
  variant: 'primary' | 'secondary' | 'booking';
  size: 'lg' | 'md' | 'sm';
  type?: 'button' | 'submit' | 'reset';
  children: React.ReactNode;
}

const Button = ({
  variant,
  size,
  onClick,
  className,
  children,
  ...rest
}: ButtonProps) => {
  const classes = useMemo(() => {
    return (
      classNames({
        'border-2 border-gray-500 bg-gray-500 text-white hover:bg-gray-600':
          !variant || variant === 'primary',
        'border-2 border-gray-500 text-gray-500 hover:bg-gray-500 hover:text-white':
          variant === 'secondary',
        'bg-yellow-500 text-black hover:bg-yellow-600 whitespace-nowrap': variant === 'booking', // New booking variant
        'px-6 py-3 text-lg': variant !== 'booking' && size === 'lg',
        'px-3 py-2 text-base': variant !== 'booking' &&  !size || size === 'md',
        'px-2 py-1 text-sm': variant !== 'booking' &&  size === 'sm',
        'text-center transition duration-300 ease-in-out rounded-full flex flex-row justify-between items-center hover:no-underline no-underline':
          true,
      }) +
      ' ' +
      className
    );
  }, [variant, size, className]);

  return (
    <button className={classes} {...rest}>
      {children}
    </button>
  );
};

export default Button;
