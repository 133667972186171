import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

const BackgroundComponent = ({ showImageBG }) => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allContentfulAsset(filter: { title: { glob: "raw*" } }) {
        nodes {
          title
          gatsbyImageData(
            layout: FULL_WIDTH
            formats: [WEBP]
            width: 1920
            height: 1080
            placeholder: BLURRED
          )
          url
        }
      }
    }
  `);

  const [bgImageUrl, setBgImageUrl] = useState(null);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [base64Placeholder, setBase64Placeholder] = useState(null);

  useEffect(() => {
    // Select a random image
    const randomIndex = Math.floor(
      Math.random() * data.allContentfulAsset.nodes.length
    );
    const selectedImage = data.allContentfulAsset.nodes[randomIndex];
    
    if (selectedImage) {
      // Set the base64 placeholder initially
      setBase64Placeholder(selectedImage.gatsbyImageData.placeholder.fallback);
      
      // Preload the actual image using the JavaScript Image object
      const imageLoader = new Image();
      imageLoader.src = selectedImage.url;
      imageLoader.onload = () => {
        setBgImageUrl(selectedImage.url);
        setIsImageLoaded(true); // Trigger fade-in transition once fully loaded
      };
    }
  }, [data.allContentfulAsset.nodes]);

  if (!showImageBG) return null;

  return (
    <style
      dangerouslySetInnerHTML={{
        __html: `
          body:before {
            background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${isImageLoaded ? bgImageUrl : base64Placeholder});
            background-position: center;
            background-size: cover;
            content: '';
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            z-index: -1;
            opacity: ${isImageLoaded ? 1 : 0};
            transition: opacity 0.5s ease-in-out;
          }
        `,
      }}
    />
  );
};

export default BackgroundComponent;
