import React, { VFC } from "react";
import FacebookIcon from "../Icons/FacebookIcon";
import InstagramIcon from "../Icons/InstagramIcon";
import LinkedInIcon from "../Icons/LinkedInIcon";
import SoundCloudIcon from "../Icons/SoundCloudIcon";
import TwitterIcon from "../Icons/TwitterIcon";
import VimeoIcon from "../Icons/VimeoIcon";
import YouTubeIcon from "../Icons/YouTubeIcon";

const commonClassName = "block text-white opacity-40 hover:opacity-90 h-5 w-5 md:h-6 md:w-6 duration-300 cursor-pointer";

const Socials: VFC = () => {
    return (
        <div className="flex flex-row items-center">
            {/* <p
                className="nav-share-title text-sm mb-4 font-thin"
                style={{ writingMode: "vertical-rl" }}
            >
                share
            </p> */}
            <div className="px-1 py-1">
                <a href="https://www.instagram.com/raha_faridi/" className={commonClassName} target="_blank" rel="noreferrer">
                    <InstagramIcon />
                </a>
            </div>
            <div className="px-1 py-1">
                <a href="https://www.facebook.com/RahaaFaridi" className={commonClassName} target="_blank" rel="noreferrer">
                    <FacebookIcon />
                </a>
            </div>
            <div className="px-1 py-1">
                <a href="https://twitter.com/RahaFaridi" className={commonClassName} target="_blank" rel="noreferrer">
                    <TwitterIcon />
                </a>
            </div>
            <div className="px-1 py-1">
                <a href="https://www.linkedin.com/in/rahafaridi/" className={commonClassName} target="_blank" rel="noreferrer">
                    <LinkedInIcon />
                </a>
            </div>
            <div className="px-1 py-1">
                <a href="https://soundcloud.com/rahafaridi" className={commonClassName} target="_blank" rel="noreferrer">
                    <SoundCloudIcon />
                </a>
            </div>
            <div className="px-1 py-1">
                <a href="https://vimeo.com/rahafaridi" className={commonClassName} target="_blank" rel="noreferrer">
                    <VimeoIcon />
                </a>
            </div>
            <div className="px-1 py-1">
                <a href="https://www.youtube.com/channel/UCABsxch4KH_xe9QPwLs8k6w" className={commonClassName} target="_blank" rel="noreferrer">
                    <YouTubeIcon />
                </a>
            </div>
            <div className="px-1 py-1">
                <a href="mailto:contact@rahafaridi.com" className={commonClassName}>
                    <EmailIcon />
                </a>
            </div>
           
        </div>
    );
};

const EmailIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 md:h-6 md:w-6 transition-all" viewBox="0 0 20 20" fill="currentColor">
        <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
        <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
    </svg>;
};

export default Socials;
