import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const Seo: FC<any> = ({ description, lang, title, image, url }) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `);

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;
  const metaImage = image || 'https://rahafaridi.com/image.jpg';
  const metaUrl = url || "https://rahafaridi.com";

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title || defaultTitle}
      titleTemplate={defaultTitle ? `Raha Faridi: %s` : undefined}
      defaultTitle={defaultTitle}
      // onChangeClientState={(newState) => console.log(newState)}
    >
      {/* Basic Meta Tags */}
      <meta name="description" content={metaDescription} />
      <meta
        property="og:title"
        content={`Raha Faridi${title ? `: ${title}` : ''}`}
      />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={metaImage} />
      <meta property="twitter:image" content={metaImage} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content={`Raha Faridi${title ? `: ${title}` : ''}`}
      />
      <meta name="twitter:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      {/*  */}
      <link rel="canonical" href="https://rahafaridi.com" />
      <meta name="robots" content="index, follow" />
      {/* <meta property="og:locale" content="en_US" /> */}
      <meta property="og:site_name" content="Raha Faridi" />
      {/* <meta name="twitter:site" content="@rahafaridi" /> */}
      <meta name="twitter:domain" content="rahafaridi.com" />
      {/* Structured Data for SEO */}
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "Website",
            "url": "${metaUrl}",
            "name": "Raha Faridi",
            "description": "${metaDescription}",
            "image": "${metaImage}"
          }
        `}
      </script>
    </Helmet>
  );
};

export default Seo;
