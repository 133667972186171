import React, { VFC } from "react";

type Props = {
    children: React.ReactNode;
    className?: string;
}

const ListCardText: VFC<Props> = ({ children , className = "" }) => {
    return (
        <div className={"list-card-text relative -mt-6 sm:-mt-6 md:-mt-6 lg:-mt-6 xl:-mt-8 " + className}>
            <div className="text-white p-2 rounded ">
                <div className="relative">
                    <h4 className={"px-2 inline-block font-bold tracking-wide leading-none text-center filter drop-shadow-year w-full uppercase text-5xl xs:text-5xl sm:text-4xl lg:text-4xl xl:text-5xl 2xl:text-6xl"}>
                        {children}
                    </h4>
                </div>
            </div>
        </div>
    );
};

export default ListCardText;
