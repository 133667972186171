import React, { VFC } from "react";
import classNames from "classnames";

export interface GridWrapperProps {
    id?: string;
    children?: React.ReactNode;
    className?: string;
    type?: "flex" | "grid";
    ignoreDefaults?: boolean;
    style?: any;
}

const GridWrapper: VFC<GridWrapperProps> = ({
    id,
    children,
    className = "",
    type = "flex",
    style,
    // ignoreDefaults = false
}) => {
    
    const classes = classNames(
        "overflow-x-hidden overflow-y-hidden",
        {
            "flex flex-wrap": type === "flex",
            "grid justify-around":type === "grid",
            "grid-col-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3": type === "grid" ,
        }
    );
    
    return (
        <div id={id} className={`${classes} ${className}`} style={style}>
            {children}
        </div>
    );
};

export default GridWrapper;
